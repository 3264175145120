import { FC } from "react";
import { IconProps } from "..";

export const PayPal: FC<IconProps> = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 192 192"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    className={className}
  >
    <path
      fill="#000000"
      d="m34.362 134.825-5.935-.881 5.935.881Zm15.955-107.47 5.935.882-5.935-.881ZM69 92v-6a6 6 0 0 0-5.93 5.088L69 92Zm-10.498 68.825 5.935.881-5.935-.881Zm27.542 2.391-5.93-.912 5.93.912ZM93 118v-6a6 6 0 0 0-5.93 5.088L93 118ZM56.527 28H100V16H56.527v12Zm-16.23 107.706L56.252 28.237l-11.87-1.762-15.955 107.469 11.87 1.762ZM42.272 150H61v-12H42.272v12ZM69 98h31V86H69v12Zm-2.07 46.912 8-52-11.86-1.824-8 52 11.86 1.824Zm-2.493 16.794 2.498-16.825-11.87-1.762-2.498 16.825 11.87 1.762ZM66.415 176h11.722v-12H66.415v12Zm25.559-11.871 6.956-45.217-11.86-1.824-6.957 45.216 11.86 1.825ZM93 124h31v-12H93v12Zm31 0c22.644 0 41-18.356 41-41h-12c0 16.016-12.984 29-29 29v12Zm41-41c0-18.498-12.245-34.12-29.058-39.232l-3.491 11.481C144.351 58.867 153 69.931 153 83h12Zm-86.863 93a14 14 0 0 0 13.837-11.871l-11.86-1.825A2 2 0 0 1 78.136 164v12Zm-49.71-42.056C27.172 142.399 33.718 150 42.272 150v-12a1.998 1.998 0 0 1-1.975-2.294l-11.87-1.762Zm24.14 26C51.312 168.402 57.865 176 66.415 176v-12a2 2 0 0 1-1.978-2.294l-11.87-1.762ZM100 28c13.878 0 25.492 9.755 28.334 22.787l11.725-2.557C136.04 29.802 119.639 16 100 16v12Zm28.334 22.787c.435 1.996.666 4.074.666 6.213h12c0-3.004-.324-5.939-.941-8.77l-11.725 2.557ZM100 98c22.644 0 41-18.356 41-41h-12c0 16.016-12.984 29-29 29v12ZM56.527 16a12.278 12.278 0 0 0-12.145 10.475l11.87 1.762a.278.278 0 0 1 .275-.237V16Z"
    />
  </svg>
);
