import { FC } from "react";
import { IconProps } from "..";

export const BitBucket: FC<IconProps> = ({ className }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M0.5 0.5V0C0.355054 0 0.217242 0.0628994 0.122273 0.172399C0.0273044 0.281899 -0.0154732 0.427222 0.00502527 0.570711L0.5 0.5ZM14.5 0.5L14.995 0.570711C15.0155 0.427222 14.9727 0.281899 14.8777 0.172399C14.7828 0.0628994 14.6449 0 14.5 0V0.5ZM12.5 14.5V15C12.7488 15 12.9598 14.817 12.995 14.5707L12.5 14.5ZM2.5 14.5L2.00503 14.5707C2.04021 14.817 2.25117 15 2.5 15V14.5ZM5 4.5V4C4.86017 4 4.72674 4.05855 4.63206 4.16145C4.53738 4.26434 4.49012 4.40218 4.50173 4.54152L5 4.5ZM9.5 10.5V11C9.76004 11 9.97668 10.8007 9.99827 10.5415L9.5 10.5ZM5.5 10.5L5.00173 10.5415C5.02332 10.8007 5.23996 11 5.5 11V10.5ZM0.5 1H14.5V0H0.5V1ZM14.005 0.429289L12.005 14.4293L12.995 14.5707L14.995 0.570711L14.005 0.429289ZM12.5 14H2.5V15H12.5V14ZM2.99497 14.4293L0.994975 0.429289L0.00502527 0.570711L2.00503 14.5707L2.99497 14.4293ZM5 5H10V4H5V5ZM9.50173 4.45848L9.00173 10.4585L9.99827 10.5415L10.4983 4.54152L9.50173 4.45848ZM9.5 10H5.5V11H9.5V10ZM5.99827 10.4585L5.49827 4.45848L4.50173 4.54152L5.00173 10.5415L5.99827 10.4585ZM10 5H14V4H10V5Z"
      fill="#000000"
    />
  </svg>
);
